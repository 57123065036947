


























import { Vue, Component } from "vue-property-decorator";
import Logs from "@/store/logs";
import FancyButton from "@/components/FancyButton.vue";

@Component({
  components: {
    FancyButton
  }
})
export default class extends Vue {
  headers = [
    { text: "Type", value: "type" },
    { text: "Amount", value: "amount" },
    { text: "Subscriber", value: "sub" },
    { text: "Location", value: "location" },
    { text: "Date", value: "date"}
  ];

  get logs() {
    return Logs.logs;
  }

  get loading() {
    return Logs.loading;
  }

  get error() {
    return Logs.error;
  }

  async mounted() {
    this.refresh();
  }

  async refresh() {
    await Logs.fetchAll();
  }

  reload() {
    this.refresh();
    this.$forceUpdate();
  }
}
