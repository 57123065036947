var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Logs")]),_c('p',[_vm._v("Here you can see a list of Return and Take events.")]),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('fancy-button',{attrs:{"text":"Refresh","icon":"mdi-refresh"},on:{"click":function($event){return _vm.reload()}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expand-transition',[(_vm.error)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.logs,"headers":_vm.headers,"sort-by":['createdAt'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(String(item.type).toLocaleLowerCase()))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.date)))])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/locations/details/" + (item.location.slug))}},[_vm._v(_vm._s(item.location.name))])]}},{key:"item.sub",fn:function(ref){
var item = ref.item;
return [(item.sub)?_c('router-link',{attrs:{"to":("/subscribers/details/" + (item.sub._id))}},[_vm._v(_vm._s(item.sub.name))]):_c('div',[_vm._v("Unknown or deleted")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }